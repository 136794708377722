import React from "react";

const ImageDisplayWithButtons = () => {
  // URLs
  const urls = {
    encrypted: "http://fcic.cc:11031/mqtt92_encrypted.aspx",
    decrypted: "http://fcic.cc:11031/mqtt92_decrypted.aspx",
  };

  // Function to handle button click
  const handleButtonClick = (buttonKey) => {
    window.location.href = urls[buttonKey]; // Navigate to the link in the same page
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <button
          onClick={() => handleButtonClick("encrypted")}
          style={buttonStyle}
        >
          Encrypted
        </button>
        <button
          onClick={() => handleButtonClick("decrypted")}
          style={buttonStyle}
        >
          Decrypted
        </button>
      </div>
    </div>
  );
};

// Inline styles for buttons
const buttonStyle = {
  padding: "10px 20px",
  margin: "0 10px",
  fontSize: "16px",
  cursor: "pointer",
  border: "1px solid #ccc",
  borderRadius: "5px",
  backgroundColor: "#f0f0f0",
};

export default ImageDisplayWithButtons;
