

import React, { useState, useEffect } from 'react';
import './InverterControl.css';
import InverterSelection from './InverterControl';
import InverterDetails from './InverterDetails';

const InverterControl = () => {
  const [inverterModels, setInverterModels] = useState([]);
  const [selectedInverter, setSelectedInverter] = useState(null);
  const [inverterDetails, setInverterDetails] = useState([]);
  const [modalVisible, setModalVisible] = useState(false); 
  const [pendingAction, setPendingAction] = useState(null); 
  const [activePowerModalVisible, setActivePowerModalVisible] = useState(false); 
  const [activePowerValue, setActivePowerValue] = useState(0);
  const selectedPlant = 'Demo';
  
  useEffect(() => {
    const plantInverters = {
      Demo: ['100KTL-M2'],
    };
  
    if (selectedPlant && plantInverters[selectedPlant]) {
      const models = plantInverters[selectedPlant].map((model, index) => ({
        id: index + 1,
        name: model,
        status: true,
      }));
      setInverterModels(models);
    } else {
      setInverterModels([]);
    }
  }, [selectedPlant]);
  
  const fetchInverterDetails = async (inverterName) => {
    try {
      const response = await fetch('/api/remoteinverter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ plant: selectedPlant, deviceModel: inverterName }),
      });
      const data = await response.json();
      setInverterDetails(data); 
    } catch (error) {
      console.error('Error fetching inverter details:', error);
    }
  };
  
  const handleInverterSelection = (inverter) => {
    setSelectedInverter(inverter);
    fetchInverterDetails(inverter.name);
  };

  const handleToggleConfirmation = (inverter) => {
    setSelectedInverter(inverter);
    setPendingAction(inverter.status ? 'Off' : 'On');
    setModalVisible(true);
  };

  // const confirmToggle = () => {
  //   const updatedModels = inverterModels.map((inv) =>
  //     inv.id === selectedInverter.id
  //       ? { ...inv, status: !inv.status } 
  //       : inv
  //   );
  //   setInverterModels(updatedModels);
  //   setModalVisible(false); 
  //   setSelectedInverter(null); 
  // };

  const confirmToggle = async () => {
    if (!selectedInverter) return;
  
    const payload = {
      device: selectedInverter.name,
      register: pendingAction === 'On' ? 40201 : 40202,
      value: 0,
      type: 'i16',
      flag: 0,
    };
    console.log("Request Data:", JSON.stringify(payload));
    try {
      const response = await fetch("/api/external/inverter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      // Log response status for debugging
      console.log(`Response Status: ${response.status}`);
      const result = await response.json();
      console.log("API Response:", result);
  
      if (!response.ok) {
        throw new Error(`Failed to toggle inverter: ${response.status}`);
      }
  
      
      const updatedModels = inverterModels.map((inv) =>
        inv.id === selectedInverter.id
          ? { ...inv, status: !inv.status }
          : inv
      );
      setInverterModels(updatedModels);
      setModalVisible(false);
      setSelectedInverter(null);
    } catch (error) {
      console.error("Error toggling inverter:", error);
      alert(`Failed to toggle inverter: ${error.message}`);
    }
  };
  

  const cancelToggle = () => {
    setModalVisible(false); 
    setSelectedInverter(null); 
  };

  
  const openActivePowerModal = () => {
    setActivePowerModalVisible(true);
  };

  const closeActivePowerModal = () => {
    setActivePowerModalVisible(false);
  };

  const handleActivePowerChange = (e) => {
    setActivePowerValue(e.target.value);
  };

  // const applyActivePowerAdjustment = () => {
    
  //   // console.log('Adjusting Active Power to:', activePowerValue);
  //   setActivePowerModalVisible(false); 
  // };
  const applyActivePowerAdjustment = async () => {
    const requestData = {
      device: "100KTL-M2", 
      register: 45663, 
      value: activePowerValue, 
      type: "i16", 
      flag: 0, 
    };
  
    // Log the request data that will be sent
    console.log("Sending Active Power Adjustment:", JSON.stringify(requestData));
  
    try {
      const response = await fetch('/api/external/inverter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData), 
      });
  
     
      console.log(`Response Status: ${response.status}`);
  
     
      const result = await response.json();
      console.log("API Response:", result);
  
      if (!response.ok) {
        throw new Error(`Failed to adjust active power: ${response.status}`);
      }
  
      
      setActivePowerModalVisible(false);
  
    } catch (error) {
      console.error("Error applying active power adjustment:", error);
    }
  };
  
  return (
    <div className="inverter-control">
      
      {/* Inverter Selection Section */}
      <InverterSelection
        inverterModels={inverterModels}
        setSelectedInverter={handleInverterSelection}
        handleToggleConfirmation={handleToggleConfirmation}
        openActivePowerModal={openActivePowerModal}
      />

     
      <InverterDetails inverterDetails={inverterDetails} inverterModels={inverterModels}
        setSelectedInverter={handleInverterSelection}
        handleToggleConfirmation={handleToggleConfirmation}
        openActivePowerModal={openActivePowerModal}/>

     
      {activePowerModalVisible && (
        <div className="active-power-modal">
          <div className="modal-contents">
            <h4>Active Power Adjustment</h4>
            <div className="info-item">
              <strong>Device Name:</strong> 100KTL-M2
            </div>
            <div className="info-item">
              <strong>Control Type:</strong> Active Power Adjustment
            </div>
            <div className="info-item">
              <strong>Adjustment Value:</strong>
              <input
                type="number"
                value={activePowerValue}
                onChange={handleActivePowerChange}
                min="0"
                max="55"
              /> kW
            </div>
            <div className="info-item">
              <strong>Adjustment Range:</strong> [0.000~55.000]
            </div>
            <div className='active-button'>
            <button onClick={applyActivePowerAdjustment}>Apply</button>
            <button onClick={closeActivePowerModal}>Cancel</button>
          </div>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {modalVisible && selectedInverter && (
        <div className="inverter-modal">
          <div className="makesure">
            <p>
              Are you sure you want to turn {pendingAction} {selectedInverter.name}?
            </p>
            <button onClick={confirmToggle}>OK</button>
            <button onClick={cancelToggle}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InverterControl;
