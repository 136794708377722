

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation to track current path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faKey, faUserCog } from '@fortawesome/free-solid-svg-icons';
import solarpanel from '../img/solarpanel (2).png';
import '../styles/Header.css';

const displayNameMapping = {
  'Bodyknits': 'Bodynits',
  'Sweelee': 'SweeLee',
  'SGWireman': 'SG Wireman',
  'mx': 'mx',
  'Plant A': 'Plant A',
  'Plant B':'Plant B',
  '32tuas':"32Tuas"
};

const Header = ({ selectedPlant, setSelectedPlant, onLogout }) => {
  const [availablePlants, setAvailablePlants] = useState([]);
  const [isFinanceModuleActive, setIsFinanceModuleActive] = useState(false); // New state variable
  const location = useLocation(); // Hook to get current location

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const userType = localStorage.getItem('userType');
    console.log("usertypefor:",userType,userId);

    if (userId === '1') {
      setAvailablePlants(['Bodyknits']);
      if (selectedPlant !== 'Bodyknits') {
        setSelectedPlant('Bodyknits');
      }
    } else if (userId === '2') {
      setAvailablePlants(['Sweelee']);
      if (selectedPlant !== 'Sweelee') {
        setSelectedPlant('Sweelee');
      }
    }
    else if (userId === '32') {
      setAvailablePlants(['80Tuas']);
      if (selectedPlant !== '80Tuas') {
        setSelectedPlant('80Tuas');
      }
    } 
    else if (userId === '33') {
      setAvailablePlants(['32tuas']);
      if (selectedPlant !== '32tuas') {
        setSelectedPlant('32tuas');
      }
    }else if (userId === '34') {
      setAvailablePlants(['36Tuas']);
      if (selectedPlant !== '36Tuas') {
        setSelectedPlant('36Tuas');
      }
    }else if (userId === '35') {
      setAvailablePlants(['40Tuas']);
      if (selectedPlant !== '40Tuas') {
        setSelectedPlant('40Tuas');
      }
    }
    else if (userId === '36') {
      setAvailablePlants(['15Tech']);
      if (selectedPlant !== '15Tech') {
        setSelectedPlant('15Tech');
      }
    }
    else if (userId === '38') {
      setAvailablePlants(['NicoSteel']);
      if (selectedPlant !== 'NicoSteel') {
        setSelectedPlant('NicoSteel');
      }
    }else if (userId === '40') {
      setAvailablePlants(['Demo','Encrypted']);
      
      if (![ 'Encrypted'].includes(selectedPlant)) {
        setSelectedPlant('Demo'); 
      }
    } else if (userId === '25') {
      setAvailablePlants(['SGWireman']);
      if (selectedPlant !== 'SGWireman') {
        setSelectedPlant('SGWireman');
      }
    } else if (userId === '28') {
      setAvailablePlants(['mx']);
      if (selectedPlant !== 'mx') {
        setSelectedPlant('mx');
      }
    } else if (userType === '4') {
      setAvailablePlants(['Bodyknits']);
      setSelectedPlant('Bodyknits');
    } else if (userType === '5') { 
      setAvailablePlants(['Bodyknits', 'Sweelee']);
      if (selectedPlant !== 'Bodyknits' && selectedPlant !== 'Sweelee') {
        setSelectedPlant('Bodykniyts'); // Default to Plant A
      }
    }  else if (userType === '6') {
      setAvailablePlants([ '32tuas','80Tuas', '36Tuas', '40Tuas', '15Tech']);
      if (![ '32tuas','80Tuas', '36Tuas', '40Tuas', '15Tech'].includes(selectedPlant)) {
        setSelectedPlant('32tuas'); // Default to 80Tuas if no valid selection
      }
    } else if (userType === '7') {
      setAvailablePlants([ 'NicoSteel']);
      if (![ 'NicoSteel'].includes(selectedPlant)) {
        setSelectedPlant('NicoSteel'); // Default to 80Tuas if no valid selection
      }
    } 
     else {
      setAvailablePlants(['Bodyknits', 'Sweelee','32tuas','NicoSteel']);
      if (!['Bodyknits', 'Sweelee','32tuas','NicoSteel'].includes(selectedPlant)) {
        setSelectedPlant('Bodyknits');
      }
    }
  }, [setSelectedPlant, selectedPlant]);

  // Check if the current path is the financial module path
  useEffect(() => {
    if (
      location.pathname==='/newfinanceprojected' ||
      location.pathname === '/plant' ||
    location.pathname === '/device' ||
    location.pathname === '/userinfo'
    ) {
      setIsFinanceModuleActive(true);
    } else {
      setIsFinanceModuleActive(false);
    }
  }, [location.pathname]);

  const handlePlantChange = (event) => {
    const newPlant = event.target.value;
    if (selectedPlant !== newPlant) {
      setSelectedPlant(newPlant);
    }
  };

  const handleLogoutClick = () => {
    onLogout();
  };

  // const iconStyle = {
  //   width: '20%',
  // };
  // const userIconStyle = {
  //   fontSize: '30px',
  // };
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');

  return (
    <Navbar bg="light" expand="lg" className="navbar-custom">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavDropdown title="Home" id="basic-nav-dropdown">
            {/* <NavDropdown.Item as={Link} to="/overview" className='nav-list'>Overview</NavDropdown.Item> */}
            <NavDropdown.Item as={Link} to="/newoverview" className='nav-list'>Overview</NavDropdown.Item>
            {userId !== '32'  && userId !=='34' && userId !=='35' &&userId !=='36' && userId !=='40'&& (
            <NavDropdown.Item as={Link} to="/map" className='nav-list'>Mapview</NavDropdown.Item>  )}
          </NavDropdown>

          {userType !== '1' && userType !== '3' && userType !=='4' && userType !=='8' && userId !=='40'&& (
            <NavDropdown title="Monitoring" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/reports"  className='nav-list'>Monitoring</NavDropdown.Item>
            </NavDropdown>
          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && userType !=='6' && userType !=='7' && userType !=='8' && userId !=='40'&&(
            <NavDropdown title="Financial Module" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/newfinance" className='nav-list'>Financial Module</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/newfinanceprojected" className='nav-list'>Projected Model</NavDropdown.Item> */}
            </NavDropdown>
            
          )}

          {userType !== '1' && userType !== '3' && userType !== '4' && userType !== '7' && userType !=='8' && userId !=='40'&&(
            <NavDropdown title="Alarm" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/alarm" className='nav-list'>Alarm</NavDropdown.Item>
            </NavDropdown>
          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && userType !=='6' && userType !=='7' && userType !=='8' && userId !=='40'&& (
           <NavDropdown title="Plants" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/plant" className='nav-list'>Plant Management</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/device" className='nav-list'>Device Management</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/invertercontrol" className='nav-list'>Remote Management</NavDropdown.Item>
              
          </NavDropdown>
             )}
 {userId==='40' && (
            <NavDropdown title="Screenshots" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/screenshots" className='nav-list'>Screenshots</NavDropdown.Item>
            </NavDropdown>
          )}
          {userId==='40' && (
            <NavDropdown title="LiveLink" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/livelink" className='nav-list'>LiveLink</NavDropdown.Item>
            </NavDropdown>
          )}

          <NavDropdown title="Logout" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/" onClick={handleLogoutClick} className='nav-list'>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>

        <Nav className="plant-select">
        {/* {userType !== '9' && userType !=='4' && userType!=='5' &&  userId!=='32' &&userId!=='33' && userId!=='34' && userId!=='35' &&  userId!=='36' && userId!=='37' &&  (
            <NavDropdown title="Request" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/request" className='nav-list'>Request Page</NavDropdown.Item>
            </NavDropdown>
          )} */}
          {userId === '1' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon' /> : Bodyknits
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>: User
              </Nav.Item>
            </>
          )}
          {userId === '2' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : Sweelee
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
           {userId === '32' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : 80Tuas
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
           {userId === '33' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : 32Tuas
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
          {userId === '40' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : Demo
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  Demo
              </Nav.Item>
            </>
          )}
           {userId === '34' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : 36Tuas
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
           {userId === '35' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : 40Tuas
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
           {userId === '36' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : 15Tech
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>:  User
              </Nav.Item>
            </>
          )}
           {userId === '38' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : AdminNS
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>: AdminNS
              </Nav.Item>
            </>
          )}
          {userType === '4' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon' /> :TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon' /> : User
              </Nav.Item>
            </>
          )}
          {userType === '9' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon' /> : TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon' /> : Super Admin
              </Nav.Item>
            </>
          )}
          {userType === '3' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/> : Owner
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon' /> : Owner
              </Nav.Item>
            </>
          )}
          {userType === '2' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/>: Admin
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey}  className='key-icon'/>: Admin
              </Nav.Item>
            </>
          )}
{userType === '6' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/>: AdminSG
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey}  className='key-icon'/>: AdminSG
              </Nav.Item>
            </>
          )}
          {userType === '7' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon'/>: AdminNS
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey}  className='key-icon'/>: AdminNS
              </Nav.Item>
            </>
          )}
          {userType === '5' && (
            <>
              <Nav.Item className="nav-item">
                <FontAwesomeIcon icon={faUserCheck} className='user-icon' /> : TRE-Solutions
              </Nav.Item>
              <Nav.Item>
                <FontAwesomeIcon icon={faKey} className='key-icon'/>: Admin
              </Nav.Item>
            </>
          )}

          {!isFinanceModuleActive && ( 
            
            <Nav.Item>
  <img src={solarpanel} alt="solarpanel" className='icon-style' />
  :
  <select
    id="PlantAdmin"
    onChange={handlePlantChange}
    value={selectedPlant} // Use selectedPlant directly
    className="form-control"
  >
    {availablePlants.map((plant) => (
      <option key={plant} value={plant}>
        {plant === 'Bodyknits' && (userType === '4' || userType === '5') ? 'Plant A' : 
         plant === 'Sweelee' && userType === '5' ? 'Plant B' : 
         plant==='Demo' && userId ==='40' ? 'Decrypted' :
         displayNameMapping[plant] || plant}
      </option>
    ))}
  </select>
</Nav.Item>

          )}

          {userType !== '1' && userType !== '2' && userType !== '3' && userType !== '4' && userType !=='5' && userType !=='6' &&userType !=='7' && userType !=='8' && userId!=='40' &&(
            <Nav.Item className="nav-item">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="user-management-tooltip">User Management</Tooltip>}
              >
                <Link to="/userinfo">
                  <FontAwesomeIcon icon={faUserCog} className='user-icon-style' />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
          )}
          
          { userType ==='6' &&(
            <Nav.Item className="nav-item">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="user-management-tooltip">User Management</Tooltip>}
              >
                <Link to="/tuasmanage">
                  <FontAwesomeIcon icon={faUserCog} className='user-icon-style' />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
          )}
          { userType ==='8' &&(
            <Nav.Item className="nav-item">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="user-management-tooltip">User Management</Tooltip>}
              >
                <Link to="/nicosteelmanage">
                  <FontAwesomeIcon icon={faUserCog} className='user-icon-style' />
                </Link>
              </OverlayTrigger>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
