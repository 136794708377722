import React, { useState } from 'react';
import './Dashboard.css'; 
import ProfitLoss from './ProfiLoss'; 
import SolarTable from './SolarTable';
import PPATable from './PPATable';
import RECTable from './RECTable';

const Dashboard = ({yearlyValue,setYearlyValue,yearToDateValue,setYearToDateValue,selectedYear,handleYearChange,selectedPlant}) => {
  const [activeTable, setActiveTable] = useState('solar');
  const getDisplayPlantName = (plant) => {
  
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};
const displayPlantName = getDisplayPlantName(selectedPlant);
  return (
    <div>
      <h3 style={{ color: "red" }}>TREK RENEWABLE ENERGY SOLUTIONS PTE. LTD.</h3>
      <h4> {displayPlantName} Project</h4>
      
      
      <div className="dashboard-container">
        <div className="right-table-container">
        
         
      <ProfitLoss yearlyValue={yearlyValue}  year={selectedYear} selectedPlant={selectedPlant}/>
        </div>

        <div className="left-table-container">
          <div className="button-container">
            <button
              onClick={() => setActiveTable('solar')}
              className={activeTable === 'solar' ? 'active' : ''}
              style={{ marginRight: '10px' }}
            >
              Solar Generation
            </button>

            <button
              onClick={() => setActiveTable('ppa')}
              className={activeTable === 'ppa' ? 'active' : ''}
              style={{ marginRight: '10px' }}
            >
              PPA Revenue
            </button>

            <button
              onClick={() => setActiveTable('rec')}
              className={activeTable === 'rec' ? 'active' : ''}
            >
              REC Revenue
            </button>
          </div>
          
          {/* Year Picker */}
          <div style={{ margin: '10px 0' }}>
            <label htmlFor="year-picker">Select Year: </label>
            <input
              id="year-picker"
              type="number"
              value={selectedYear}
              onChange={handleYearChange}
              min="2000" 
              max={new Date().getFullYear() + 10} 
              style={{ marginLeft: '10px' }}
            />
          </div>

       
          {activeTable === 'solar' && <SolarTable year={selectedYear} selectedPlant={selectedPlant}/>}
          {activeTable === 'ppa' && <PPATable year={selectedYear} 
          yearlyValue={yearlyValue} setYearlyValue={setYearlyValue} 
          yearToDateValue={yearToDateValue} setYearToDateValue={setYearToDateValue} selectedPlant={selectedPlant}
          />}
          {activeTable === 'rec' && <RECTable year={selectedYear} selectedPlant={selectedPlant}/>}
         
        </div>
      </div>

     
    </div>
  );
};

export default Dashboard;
