import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserInfoTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';

const userTypes = {
  1: 'User',
  2: 'Admin',
  3: 'Owner',
  9: 'SuperAdmin',
  6:'AdminSG',
  '': ''
};

const UserInfoTable = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.post('/user/info');
        // Filter only specific user IDs
        const filteredUsers = response.data.filter((user) =>
          [37,32, 33, 34, 35, 36].includes(user.UserId)
        );
        setUserInfo(filteredUsers);
      } catch (error) {
        setError('Failed to fetch user information. Please log out and log in again.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleEdit = (userId) => {
    setEditingUserId(userId);
    const user = userInfo.find((user) => user.UserId === userId);
    setUpdatedValues(user);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedValues({ ...updatedValues, [name]: value });
  };

  const handleUserTypeChange = (event) => {
    setUpdatedValues({ ...updatedValues, UserType: parseInt(event.target.value, 10) });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/user/update/${editingUserId}`, updatedValues);
      setUserInfo((prevUserInfo) =>
        prevUserInfo.map((user) =>
          user.UserId === editingUserId ? updatedValues : user
        )
      );
      setEditingUserId(null);
    } catch (error) {
      console.error('Failed to update user:', error.message);
    }
  };

  const handleCancel = () => {
    setEditingUserId(null);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const filteredUser = userInfo.filter((user) =>
    user.UserName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='header-container'>
      <h2>User Management</h2>

      <div className="user-container">
        <div className="user-search-bar">
          <input
            type="text"
            placeholder="Search by user name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>

      {userInfo.length > 0 ? (
        <table className="user-info-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>User Name</th>
              <th>User Type</th>
              <th>Password</th>
              <th>Plant</th>
              <th>Email</th>
              <th>Cell Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUser.length > 0 ? (
              filteredUser.map((user, index) => (
                <tr key={user.UserId}>
                  <td>{index + 1}</td>
                  {/* <td>
                    {editingUserId === user.UserId ? (
                      <input
                        type="text"
                        name="UserName"
                        value={updatedValues.UserName}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.UserName
                    )}
                  </td> */}

                  <td>{user.UserName}</td>
                  <td>
  {userTypes[user.UserType]}
</td>

                  {/* <td>
                    {editingUserId === user.UserId ? (
                      <select
                        name="UserType"
                        value={updatedValues.UserType}
                        onChange={handleUserTypeChange}
                      >
                        {Object.entries(userTypes).map(([value, label]) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      userTypes[user.UserType]
                    )}
                  </td> */}
                  <td>
                    {editingUserId === user.UserId ? (
                      <input
                        type="text"
                        name="Pwd"
                        value={updatedValues.Pwd}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.Pwd
                    )}
                  </td>
                  {/* <td>
                    {editingUserId === user.UserId ? (
                      <input
                        type="text"
                        name="Plant"
                        value={updatedValues.Plant}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.Plant
                    )}
                  </td> */}
                  <td>{user.Plant}</td>
                  <td>
                    {editingUserId === user.UserId ? (
                      <input
                        type="text"
                        name="email"
                        value={updatedValues.email}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.email
                    )}
                  </td>
                  <td>
                    {editingUserId === user.UserId ? (
                      <input
                        type="text"
                        name="cellPhone"
                        value={updatedValues.cellPhone}
                        onChange={handleInputChange}
                      />
                    ) : (
                      user.cellPhone
                    )}
                  </td>
                  <td style={{ display: 'flex', gap: '8px' }}>
                    {editingUserId === user.UserId ? (
                      <>
                        <button onClick={handleSave} style={{ margin: '0' }}>Save</button>
                        <button onClick={handleCancel} style={{ margin: '0' }}>Cancel</button>
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ cursor: 'pointer', color: 'blue', fontSize: '22px' }}
                        onClick={() => handleEdit(user.UserId)}
                        title="Edit"
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="100%" style={{ textAlign: 'center' }}>
                  No data found for the search criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div>No user info available.</div>
      )}
    </div>
  );
};

export default UserInfoTable;
