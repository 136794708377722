import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SolarTable.css';

const monthMapping = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

const SolarTable = ({ year,selectedPlant}) => {
  const [monthlyValues, setMonthlyValues] = useState({});
  const [yearlyValue, setYearlyValue] = useState('');
  const [yearToDateValue, setYearToDateValue] = useState('');
  const [projectedValues, setProjectedValues] = useState({});
  const [projectYearToDate, setProjectYearToDate] = useState('');

  const calculateYearlyValue = (values) => {
    const total = Object.values(values).reduce((acc, value) => {
      const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
      return acc + numericValue;
    }, 0);
    return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  // const plantId = "Sweelee";

  const calculateDeviation = (projected, actual) => {
   const parsedProjected = parseFloat(projected.replace(/,/g, ''));
   if (!parsedProjected || parsedProjected === 0) return null;
   return ((actual - parsedProjected) / parsedProjected) * 100;
  };
  
  const calculateYearlyDeviation = (projectedValues, yearlyValue) => {
    const totalProjected = Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0);
    const totalActual = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
    const deviation = totalProjected
      ? ((totalActual - totalProjected) / totalProjected) * 100
      : null;
  
    // Return the deviation with a sign
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
  };
  
  const calculateYearToDateDeviation = (projectYearToDate, yearToDateValue) => {
    const totalProjected = parseFloat((projectYearToDate || '0').toString().replace(/,/g, '')) || 0;
    const totalActual = parseFloat((yearToDateValue || '0').toString().replace(/,/g, '')) || 0;

    const deviation = totalProjected
        ? ((totalActual - totalProjected) / totalProjected) * 100
        : null;
   
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
};


  const getDeviationColor = (deviation) => {
    if (!deviation) return 'black'; 
    const numericValue = parseFloat(deviation);
    return numericValue < 0 ? 'red' : 'green'; 
  };
  
  useEffect(() => {
    const calculateProjectedValues = () => {
      if (selectedPlant === "Sweelee") {
        if (year === '2023' || year === 2023) {
          return { Sep: 13837.16, Oct: 13837.16, Nov: 13837.16, Dec: 13837.16 };
        } else if (year === '2024' || year === 2024) {
          return {
            Jan: 13781,
            Feb: 13781,
            Mar: 13781,
            Apr: 13781,
            May: 13781,
            Jun: 13781,
            Jul: 13781,
            Aug: 13781,
            Sep: 13781,
            Oct: 13781,
            Nov: 13781,
            Dec: 13781,
          };
        } else if (year === '2025' || year === 2025) {
          return { Jan: 13724.83 };
        }
      } else if (selectedPlant === "Bodyknits") {
        if(year === '2024' || year === 2024){
           return{  
            Jan: "14742.92",
            Feb: "14742.92",
            Mar: "14742.92",
            Apr: "14742.92",
            May: "14742.92",
            Jun: "14742.92",
            Jul: "14742.92",
            Aug: "14742.92",
            Sep: "14742.92",
            Oct: "14742.92",
            Nov: "14742.92",
            Dec: "14742.92",
           }
           }else if(year === '2025' || year === 2025){
            return{
              Jan: "14660.17",
              Feb: "",
              Mar: "",
              Apr: "",
              May: "",
              Jun: "",
              Jul: "",
              Aug: "",
              Sep: "",
              Oct: "",
              Nov: "",
              Dec: "",
            }
          }else {
            return {  
              Jan: "",
              Feb: "",
              Mar: "",
              Apr: "",
              May: "",
              Jun: "",
              Jul: "",
              Aug: "",
              Sep: "",
              Oct: "",
              Nov: "",
              Dec: "",
            }; 
          }
      } else {
        return {  
          Jan: "",
          Feb: "",
          Mar: "",
          Apr: "",
          May: "",
          Jun: "",
          Jul: "",
          Aug: "",
          Sep: "",
          Oct: "",
          Nov: "",
          Dec: "",
        }; 
      }
    };
  
    const calculateProjectYearToDate = () => {
      if (selectedPlant === "Sweelee") {
        return year === '2023' || year === 2023
          ? '55,348.64'
          : year === '2024' || year === 2024
          ? '220,720.64'
          : '234,445.47';
      } else if (selectedPlant === "Bodyknits") {
        return year === '2024' || year === 2024
          ? '176,915.04'
          : year === '2025' || year === 2025
          ? '191,575.21'
          : '0';
      } else {
        return '0'; // Default case for other plants
      }
    };
  
    const fetchData = async () => {
      try {
        const response = await axios.post(`/fmsolaryield`, {
          plant: selectedPlant,
          year: year.toString(),
        });
  
        if (response.data && Array.isArray(response.data)) {
          const pvYieldData = response.data.reduce((acc, item) => {
            const statisticalPeriod = item['StatisticalPeriod'];
            if (statisticalPeriod && typeof statisticalPeriod === 'string') {
              const month = statisticalPeriod.split('-')[1];
              const monthName = monthMapping[month];
              if (monthName) {
                acc[monthName] = parseFloat(item['TotalInverterYield']).toLocaleString();
              }
            }
            return acc;
          }, {});
  
          setMonthlyValues(pvYieldData);
  
          const totalYearly = calculateYearlyValue(pvYieldData);
          setYearlyValue(totalYearly);
  
          // if (year === 2024) {
          //   const previousYearTotal = selectedPlant === "Sweelee" ? "53,024.03" : " ";
          //   const previousYearTotalNumeric = parseFloat(previousYearTotal.replace(/,/g, '')) || 0;
          //   const totalYearlyNumeric = parseFloat(totalYearly.replace(/,/g, '')) || 0;
  
          //   const yearToDateTotal = previousYearTotalNumeric + totalYearlyNumeric;
          //   setYearToDateValue(yearToDateTotal);
          // } else {
          //   setYearToDateValue(totalYearly);
          // }
          if (year === 2025) {
            const previousYearTotal = selectedPlant === "Sweelee" ? "201,901.64" : "187,732.66 ";
            const previousYearTotalNumeric = parseFloat(previousYearTotal.replace(/,/g, '')) || 0;
            const totalYearlyNumeric = parseFloat(totalYearly.replace(/,/g, '')) || 0;
          
            const yearToDateTotal = previousYearTotalNumeric + totalYearlyNumeric;
            setYearToDateValue(yearToDateTotal);
          } else if (year === 2024) {
            const previousYearTotal = selectedPlant === "Sweelee" ? "53,024.03" : " ";
            const previousYearTotalNumeric = parseFloat(previousYearTotal.replace(/,/g, '')) || 0;
            const totalYearlyNumeric = parseFloat(totalYearly.replace(/,/g, '')) || 0;
          
            const yearToDateTotal = previousYearTotalNumeric + totalYearlyNumeric;
            setYearToDateValue(yearToDateTotal);
          } else {
            setYearToDateValue(totalYearly);
          }
          
        } else {
          console.error("Response data is not in expected array format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching the data!", error);
      }
    };
  
    setProjectedValues(calculateProjectedValues());
    setProjectYearToDate(calculateProjectYearToDate());
  
    fetchData();
  }, [year, selectedPlant]);
  

  const glitterAnimationStyle = `
  @keyframes glitter {
    0% { background-position: 0 0; }
    50% { background-position: 100% 0; }
    100% { background-position: 0 0; }
  }

  .glitterRow th {
    // background: linear-gradient(90deg, #fff, #ffeb3b, #fff);
    background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
    color: #fff;
  }
    
  .glitterRows  {
    // background: linear-gradient(90deg, #fff, #ffeb3b, #fff);
    background: linear-gradient(90deg, #32CD32, #7FFF00, #32CD32);
    background-size: 200% 100%;
    animation: glitter 3s infinite;
    color: #fff;
  }
`;

const GlitterEffectStyles = () => (
  <style>
    {glitterAnimationStyle}
  </style>
);

  return (
        <div>
      <h5 style={{ fontWeight: 'bold' }}>Solar Generation (kWh) ({year})</h5>
      <p>
  {selectedPlant === 'Sweelee' 
    ? 'Swee Lee Project (Billing cycle from 10th of current month to 9th of following month)' 
    : selectedPlant === 'Bodyknits' 
    ? 'Bodyknits Project (Billing cycle from 15th of current month to 14th of following month)' 
    : ''}
</p>

      <GlitterEffectStyles />
      <table className="solar-table" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th colSpan="4" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Monthly</th>
            <th colSpan="3" className="glitterRow"  style={{backgroundColor:"grey",color:"white"}}>Yearly ({year})</th>
            <th colSpan="3" className="glitterRow"  style={{backgroundColor:"grey",color:"white"}}>Year to Date ({year === '2023' || year === 2023 ? '1 Year' : year === '2024' || year === 2024 ? '2 Years' : ''})</th>
          </tr>
          <tr>
            <th rowSpan={2}>Period</th>
            <th colSpan={2}>Volume</th>
            <th rowSpan={2}>Deviation <br></br>(%)</th> 
            <th colSpan={2}> Volume</th>
            
            <th rowSpan={2}>Deviation <br></br>(%)</th>
            <th colSpan={2}> Volume</th>
            <th rowSpan={2}>Deviation<br></br>(%)</th>
          </tr>
          <tr>
           
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}}  className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}}className="glitterRows">Actual</th>
          </tr>
        </thead>
        
        <tbody>
  {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
    const projected = projectedValues[month]
    ? parseFloat(projectedValues[month].toString().replace(/,/g, '')).toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
    : '';

    const actual = parseFloat(monthlyValues[month]?.toString().replace(/,/g, '')) || '';
    
    const deviation = calculateDeviation(projected, actual);
    const formattedDeviation = deviation !== null
  ? deviation < 0
    ? `(${Math.abs(deviation).toFixed(2)})`  
    : `${Math.abs(deviation).toFixed(2)}`   
  : '';
    const deviationStyle = deviation !== null
      ? { color: deviation < 0 ? 'red' : 'green', fontWeight: 'bold' }
      : {};

    return (
      <tr key={month}>
        <td>{month}</td>
        <td style={{ color: "blue", fontWeight: "bold" }}>{projected.toLocaleString()}</td>
        <td style={{ color: "green", fontWeight: "bold" }}>{actual.toLocaleString()}</td>
        
        <td style={deviationStyle}>{formattedDeviation}</td>

        {index === 0 && (
          <>
           
            <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>
  {Number(
    Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0)
  ).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
</td>
            <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>
              {yearlyValue}
            </td>

            <td rowSpan="12" 
  style={{
    fontWeight: "bold",
    color: getDeviationColor(
      parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue))
    ),
  }}
>
  {(() => {
    const deviation = parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue));
    if (deviation !== null) {
      
      const formattedDeviation = deviation < 0
        ? `(${Math.abs(deviation).toFixed(2)})`  
        : `${Math.abs(deviation).toFixed(2)}`;  
    
      return formattedDeviation;
    }
    return '';  
  })()}
</td>


            <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>{projectYearToDate}</td>
            <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>{yearToDateValue.toLocaleString()}</td>
         
<td rowSpan="12"
  style={{
    fontWeight: "bold",
    color: getDeviationColor(
      parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue))
    ),
  }}
>
  {(() => {
    const deviation = parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue));
    if (deviation !== null) {
      
      const formattedDeviation = deviation < 0
        ? `(${Math.abs(deviation).toFixed(2)})`  
        : `${Math.abs(deviation).toFixed(2)}`;  
      
      
      return formattedDeviation;
    }
    return '';  
  })()}
</td>

         
          </>
        )}
      </tr>
    );
  })}
</tbody>


      </table>
    </div>
  );
};

export default SolarTable;


