// import React, { useState } from "react";
// import EncryImage from './../../img/photos/Encrypt.png';
// import DecryImage from './../../img/photos/decrypt.png';
// import DataServer from './../../img/photos/Databaseserver.png';

// const ImageDisplayWithButtons = () => {
//   const [currentImage, setCurrentImage] = useState(EncryImage); // State to manage the current image path

//   // Image paths
//   const images = {
//     encrypted: EncryImage, // Use the imported image
//     decrypted: DecryImage, // Use the imported image
//     database: DataServer,
//   };

//   return (
//     <div style={{ textAlign: "center", marginTop: "20px" }}>
//       <div style={{ marginBottom: "20px" }}>
//         <button
//           onClick={() => setCurrentImage(images.encrypted)}
//           style={buttonStyle}
//         >
//           Encrypted
//         </button>
//         <button
//           onClick={() => setCurrentImage(images.decrypted)}
//           style={buttonStyle}
//         >
//           Decrypted
//         </button>
//         <button
//           onClick={() => setCurrentImage(images.database)}
//           style={buttonStyle}
//         >
//           Database Server
//         </button>
//       </div>
//       <div>
//         {currentImage ? (
//           <img
//             src={currentImage}
//             alt="Selected Display"
//             style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }}
//           />
//         ) : (
//           <p>Please select an option to display the image.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// // Inline styles for buttons
// const buttonStyle = {
//   padding: "10px 20px",
//   margin: "0 10px",
//   fontSize: "16px",
//   cursor: "pointer",
//   border: "1px solid #ccc",
//   borderRadius: "5px",
//   backgroundColor: "yellow",
// };

// export default ImageDisplayWithButtons;
import React, { useState } from "react";
import EncryImage from './../../img/photos/Encrypt.png';
import DecryImage from './../../img/photos/decrypt.png';
import DataServer from './../../img/photos/Databaseserver.png';

const ImageDisplayWithButtons = () => {
  // State to manage the current image and active button
  const [currentImage, setCurrentImage] = useState(EncryImage);
  const [activeButton, setActiveButton] = useState("encrypted");

  // Image paths
  const images = {
    encrypted: EncryImage,
    decrypted: DecryImage,
    database: DataServer,
  };

  // Function to handle button click
  const handleButtonClick = (imageKey) => {
    setCurrentImage(images[imageKey]);
    setActiveButton(imageKey);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <div style={{ marginBottom: "20px" }}>
        <button
          onClick={() => handleButtonClick("encrypted")}
          style={{
            ...buttonStyle,
            backgroundColor: activeButton === "encrypted" ? "yellow" : "#f0f0f0",
          }}
        >
          Encrypted
        </button>
        <button
          onClick={() => handleButtonClick("decrypted")}
          style={{
            ...buttonStyle,
            backgroundColor: activeButton === "decrypted" ? "yellow" : "#f0f0f0",
          }}
        >
          Decrypted
        </button>
        <button
          onClick={() => handleButtonClick("database")}
          style={{
            ...buttonStyle,
            backgroundColor: activeButton === "database" ? "yellow" : "#f0f0f0",
          }}
        >
          Database Server
        </button>
      </div>
      <div>
        {currentImage ? (
          <img
            src={currentImage}
            alt="Selected Display"
            style={{ maxWidth: "100%", height: "auto", borderRadius: "10px" }}
          />
        ) : (
          <p>Please select an option to display the image.</p>
        )}
      </div>
    </div>
  );
};

// Inline styles for buttons
const buttonStyle = {
  padding: "10px 20px",
  margin: "0 10px",
  fontSize: "16px",
  cursor: "pointer",
  border: "1px solid #ccc",
  borderRadius: "5px",
};

export default ImageDisplayWithButtons;
