
import React from 'react';
import InverterImage from '../../img/photos/inverter.png';

const InverterSelection = ({ inverterModels, setSelectedInverter }) => {
  if (!inverterModels) {
    return <p>Loading...</p>;
  }

  return (
    <div className="inverter-list">
      <h3>Inverters</h3>
      {inverterModels.length > 0 ? (
        inverterModels.map((inv) => (
          <div key={inv.id} className="inverter-item">
            
            <button
              className="select-btn"
              onClick={() => setSelectedInverter(inv)}
            >
              <span>{inv.name}</span>
            </button>
            <img
              src={InverterImage}
              alt={`${inv.name} illustration`}
              className="inverter-image"
            />
          </div>
        ))
      ) : (
        <p>Please Select Inverter</p>
      )}
    </div>
  );
};

export default InverterSelection;
