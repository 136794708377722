
import React from 'react';
import './InverterControl.css';

const InverterDetails = ({ inverterDetails,inverterModels,openActivePowerModal,handleToggleConfirmation,setSelectedInverter }) => {
  if (!inverterDetails || inverterDetails.length === 0) {
    return <p>Please select Inverter Model</p>;
  }

  const {
    Plant,
    Time,
    DeviceType,
    DeviceModel,
    SN,
    InputPower,
    GridVoltage,
    PhaseAV,
    PhaseBV,
    PhaseCV,
    PhaseAC,
    PhaseBC,
    PhaseCC,
    ActivePower,
    ReactivePower,
    PowerFactor,
    GridFrequency,
    InternalTemp,
    InsulationResist,
    DeviceStatus,
    AccuEnergy,
    DailyEnergy,
  } = inverterDetails[0]; 

  return (
    <div className="inverter-details">
        <div>
         {inverterModels.length > 0 ? (
        inverterModels.map((inv) => (
          <div key={inv.id} className="inverter-item">
        <div className="button-group">
        <button
              className={`status-btn ${inv.status ? 'off' : 'on'}`}
              onClick={() => handleToggleConfirmation(inv)} 
            >
              {inv.status ? 'Off' : 'On'}
            </button>
            <button className="active-power-btn" onClick={openActivePowerModal}>
        Active Power Adjustment
      </button>
      </div>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </div>
      <div className="section">
        <h3>Basic Information</h3>
        <div className="info-grid">
          <div className="info-item"><strong>Plant:</strong> {Plant}</div>
          <div className="info-item"><strong>Time:</strong> {Time}</div>
          <div className="info-item"><strong>Device Type:</strong> {DeviceType}</div>
          <div className="info-item"><strong>Device Model:</strong> {DeviceModel}</div>
          <div className="info-item"><strong>SN:</strong> {SN}</div>
        </div>
      </div>

      <div className="section">
        <h3>Inverter Readings</h3>
        <div className="info-grid">
          <div className="info-item"><strong>Input Power:</strong>{InputPower} kW</div>
          <div className="info-item"><strong>Grid Voltage:</strong> {GridVoltage} V</div>
          <div className="info-item"><strong>Phase A Voltage:</strong> {PhaseAV} V</div>
          <div className="info-item"><strong>Phase B Voltage:</strong> {PhaseBV} V</div>
          <div className="info-item"><strong>Phase C Voltage:</strong> {PhaseCV} V</div>
          <div className="info-item"><strong>Phase A-C Voltage:</strong> {PhaseAC} V</div>
          <div className="info-item"><strong>Phase B-C Voltage:</strong> {PhaseBC} V</div>
          <div className="info-item"><strong>Phase C-C Voltage:</strong> {PhaseCC} V</div>
          <div className="info-item"><strong>Active Power:</strong> {ActivePower} kW</div>
          <div className="info-item"><strong>Reactive Power:</strong> {ReactivePower} kvar</div>
          <div className="info-item"><strong>Power Factor:</strong> {PowerFactor}</div>
          <div className="info-item"><strong>Grid Frequency:</strong> {GridFrequency} Hz</div>
          <div className="info-item"><strong>Internal Temperature:</strong> {InternalTemp} °C</div>
          <div className="info-item"><strong>Insulation Resistance:</strong> {InsulationResist} MΩ</div>
          <div className="info-item"><strong>Device Status:</strong> {DeviceStatus}</div>
          <div className="info-item"><strong>Accumulative Energy:</strong> {AccuEnergy} kWh</div>
          <div className="info-item"><strong>Daily Energy:</strong> {DailyEnergy} kWh</div>
        </div>
      </div>
    </div>
  );
};

export default InverterDetails;
